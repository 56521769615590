import { ClearOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  Card,
  Modal,
  Form,
  Select,
  Badge,
  Collapse,
  Flex,
  message,
  Space,
} from "antd";
import Swal from "sweetalert2";
import EmpService from "../service/Emp.service.js";
import TextArea from "antd/es/input/TextArea";
// import dayjs from "dayjs";
import { delay } from "../utils/util";
import { PROVINCE_OPTIONS } from "../utils/util";

const empService = EmpService();

function Employee() {
  const [AllEmployee, setAllEmployee] = useState([]);
  const [actionManage, setActionManage] = useState({
    action: "create",
    title: "เพิ่มพนักงาน",
    confirmText: "ยืนยัน",
  });
  const [openModalManage, setOpenModalManage] = useState(false);
  const [form] = Form.useForm();
  const [formManage] = Form.useForm();

  const [activeSearch, setActiveSearch] = useState([]);

  const init = async () => {};
  useEffect(() => {
    init();

    getData({});
    return async () => {
      await delay(400);
      // console.clear();
    };
  }, []);

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const handleSearch = () => {
    form
      .validateFields()
      .then((v) => {
        const data = { ...v };
        getData(data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const columns = [
    {
      title: "รหัสพนักงาน",
      dataIndex: "empcode",
      key: "empcode",
      //hidden: "true",
      width: "10%",
    },
    {
      title: "คำนำหน้า",
      dataIndex: "prefix",
      key: "prefix",
      width: "10%",
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "fullname",
      key: "fullname",
      width: "50%",
    },
    {
      title: "สถานะพนักงาน",
      dataIndex: "active_status",
      key: "active_status",
      width: "10%",
      render: (data) => (
        <div>
          {data === "Y" ? (
            <Badge status="success" text="เป็นพนักงาน" />
          ) : (
            <Badge status="error" text="ไม่ได้เป็นพนักงาน" />
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "position",
      width: "20%",
      fixed: "right",
      render: (text) => (
        <Button
        size="small"
        icon={
          <EditOutlined twoToneColor="#E74C3C" style={{ fontSize: ".9rem" }} />
        }
        danger
          onClick={(e) => showEditModal(text.empcode)}
        >
          
        </Button>
      ),
    },
  ].filter((item) => !item.hidden);

  const getData = (data) => {
    empService
      .search(data)
      .then((res) => {
        const { data } = res.data;
        setAllEmployee(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const showAddModal = () => {
    setActionManage({
      action: "create",
      title: "เพิ่มพนักงาน",
      confirmText: "เพิ่ม",
    });
    setOpenModalManage(true);
  };

  const showEditModal = (data) => {
    empService
      .get(data)
      .then((res) => {
        const { data } = res.data;
        formManage.setFieldsValue(data);
        setActionManage({
          action: "edit",
          title: "แก้ไขพนักงาน",
          confirmText: "แก้ใข",
        });
        setOpenModalManage(true);
      })
      .catch((err) => {});
  };

  const manageSubmit = (v) => {
    const action =
      actionManage?.action !== "create" ? empService.update : empService.create;

    action({ ...v })
      .then(async (_) => {
        getData({});
        let datamessage;
        actionManage?.action !== "create"
          ? (datamessage = "แก้ไข พนักงาน สำเร็จ")
          : (datamessage = "เพิ่ม พนักงาน สำเร็จ");
        await Swal.fire({
          title: "<strong>สำเร็จ</strong>",
          html: datamessage,
          icon: "success",
        });
        formManage.resetFields();
      })
      .catch((err) => {
        console.warn(err);
        const data = err?.response?.data;
        message.error(data?.message || "error request");
      })
      .finally(() => {
        setOpenModalManage(false);
      });
  };

  const onCalage = (value) => {
    let todayofbt = new Date(value.target.value);
    const today = new Date();
    const year = todayofbt.getFullYear();
    const yearofbt = today.getFullYear();

      formManage.setFieldsValue({
        age: yearofbt - year,
      });

    //console.log(districtList)
  };

  const CollapseItemSearch = () => {
    return (
      <>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label="รหัสพนักงาน" name="empcode">
              <Input
                placeholder="รหัสพนักงาน"
                onChange={() => handleSearch()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label="ชื่อจริง" name="firstname">
              <Input placeholder="ชื่อจริง" onChange={() => handleSearch()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label="นามสกุล" name="lastname">
              <Input placeholder="นามสกุล" onChange={() => handleSearch()} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={8} md={12} lg={12} xl={12}>
            {/* Ignore */}
          </Col>
          <Col xs={24} sm={8} md={12} lg={12} xl={12}>
            <Flex justify="flex-end" gap={8}>
              <Button
                type="primary"
                size="small"
                className="bn-action"
                icon={<SearchOutlined />}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
              <Button
                type="primary"
                size="small"
                className="bn-action"
                danger
                icon={<ClearOutlined />}
                onClick={() => handleClear()}
              >
                Clear
              </Button>
            </Flex>
          </Col>
        </Row>
      </>
    );
  };

  const FormSearch = (
    <Collapse
      size="small"
      onChange={(e) => {
        setActiveSearch(e);
      }}
      activeKey={activeSearch}
      items={[
        {
          key: "1",
          label: (
            <>
              <SearchOutlined />
              <span> Search</span>
            </>
          ),
          children: CollapseItemSearch(),
          showArrow: false,
        },
      ]}
      // bordered={false}
    />
  );
  const onModalManageClose = async () => {
    // await setCardataDetail({});
    formManage.resetFields();
    setOpenModalManage(false);
  };
  ////////////////////////////////
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const ModalManage = () => {
    return (
      <Modal
        open={openModalManage}
        title={actionManage.title}
        okText={actionManage.confirmText}
        cancelText="ยกเลิก"
        onCancel={() => onModalManageClose()}
        width={1200}
        onOk={() => {
          formManage
            .validateFields()
            .then((values) => {
              manageSubmit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={formManage} layout="vertical" autoComplete="off">
          <Card>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                รหัสพนักงาน
                <Form.Item
                  name="empcode"                  
                  rules={[
                    {
                      required: true,
                      message: "กรุณาใส่รหัสผู้ใช้!",
                    },
                  ]}
                >
                  <Input disabled={actionManage.action === "edit" ? true : false} placeholder="กรุณาใส่รหัสพนักงาน" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                คำนำหน้า
                <Form.Item
                  name="prefix"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุคำนำหน้า!",
                    },
                  ]}
                >
                  <Select
                    style={{ height: 40 }}
                    options={[
                      { value: "นาย", label: "นาย" },
                      { value: "นาง", label: "นาง" },
                      { value: "นางสาว", label: "นางสาว" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                ชื่อจริง
                <Form.Item
                  name="firstname"
                  rules={[{ required: true, message: "กรุณาใส่ชื่อจริง" }]}
                >
                  <Input placeholder="ชื่อจริง" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={12} xl={6}>
                นามสกุล
                <Form.Item name="lastname">
                  <Input placeholder="นามสกุล" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                ชื่อเล่น
                <Form.Item name="nickname">
                  <Input placeholder="ชื่อเล่น" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                เพศ
                <Form.Item name="sex">
                  <Select
                    style={{ height: 40 }}
                    options={[
                      { value: "male", label: "ชาย" },
                      { value: "female", label: "หญิง" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                ว/ด/ป เกิด
                <Form.Item name="dateofbirth">
                  <Input
                    type="date"
                    placeholder="วันเกิด"
                    onChange={(e) => {
                      onCalage(e);
                    }}
                  />
                  
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                อายุ
                <Form.Item name="age">
                  <Input placeholder="อายุ" readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                เลขประจำตัวประชาชน 13 หลัก
                <Form.Item name="citizen_id">
                  <Input type="text" placeholder="เลขประจำตัวประชาชน" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                วันที่ออกบัตร
                <Form.Item name="date_issue">
                  <Input
                    type="date"
                    placeholder="วันที่เริ่มงาน"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                วันที่หมดอายุ
                <Form.Item name="date_expiry">
                  <Input
                    type="date"
                    placeholder="วันที่เริ่มงาน"
                  />
                </Form.Item>
              </Col>
            </Row>           
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                ที่อยู่ปัจุบัน
                <Form.Item name="cur_address">
                  <TextArea rows={2} cols={40} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                จังหวัด
                <Form.Item name="province">
                  <Select
                    style={{ height: 40 }}
                    showSearch
                    filterOption={filterOption}
                    options={PROVINCE_OPTIONS}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                อำเภอ
                <Form.Item name="district">
                <Input type="text"  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                ตำบล
                <Form.Item name="subdistrict">
                <Input type="text" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                รหัสไปรษณีย์
                <Form.Item name="zipcode">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                เบอร์โทรศัพท์
                <Form.Item name="tel">
                  <Input placeholder="เบอร์โทรศัพท์" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                สัญชาติ
                <Form.Item name="nationality">
                  <Select
                    style={{ height: 40 }}
                    options={[
                      { value: "thai", label: "ไทย" },
                      { value: "laos", label: "ลาว" },
                      { value: "meanma", label: "พม่า" },
                      { value: "kmare", label: "กัมพูชา" },
                      { value: "veatnam", label: "เวียดนาม" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                วุฒิการศึกษา
                <Form.Item name="education">
                  <Input type="text" placeholder="วุฒิการศึกษา" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                สาขา
                <Form.Item name="program">
                  <Input type="text" placeholder="สาขา" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                แผนก
                <Form.Item name="department">
                  <Input placeholder="แผนก" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                ฝ่าย
                <Form.Item name="subgroup">
                  <Input placeholder="ฝ่าย" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                ตำแหน่ง
                <Form.Item name="position">
                  <Select
                    style={{ height: 40 }}
                    options={[                      
                      { value: "Employee", label: "Employee" },
                      { value: "Supervisor", label: "Supervisor" },
                      { value: "Manager", label: "Manager" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                วันที่เริ่มงาน
                <Form.Item name="startdate">
                  <Input
                    type="date"
                    placeholder="วันที่เริ่มงาน"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                ประเภทการจ้าง
                <Form.Item
                  name="salary_type"
                >
                  <Select
                    style={{ height: 40 }}
                    options={[
                      { value: "month", label: "รายเดือน" },
                      { value: "day", label: "รายวัน" },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                ค่าจ้าง
                <Form.Item name="salary">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                ชื่อบัญชีธนาคาร
                <Form.Item name="bank_account">
                  <Input placeholder="ชื่อบัญชีธนาคาร" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              เลขที่บัญชีธนาคาร
                <Form.Item name="bank_id">
                  <Input placeholder="เลขที่บัญชีธนาคาร" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={8} style={
                  actionManage.action === "edit"
                    ? { display: "inline" }
                    : { display: "none" }
                }>
                สถานะพนักงาน
                <Form.Item name="active_status">
                <Select
                    size="large"
                    options={[
                      {
                        value: "Y",
                        label: <Badge status="success" text="เปิดการใช้งาน" />,
                      },
                      {
                        value: "N",
                        label: <Badge status="error" text="ปิดการใช้งาน" />,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <div className="layout-content" style={{ padding: 20 }}>
        {/* <h1>ลูกค้าบุคคล</h1>
        <br></br> */}
        <Button
          type="primary"
          onClick={() => {
            showAddModal();
          }}
        >
          เพิ่มพนักงาน
        </Button>

        <div className="pilot-scale-access" id="area" style={{ padding: 20 }}>
          <Space
            direction="vertical"
            size="middle"
            style={{ display: "flex", position: "relative" }}
          >
            <Form form={form} layout="vertical" autoComplete="off">
              {FormSearch}
            </Form>
            <Card>
              <Row gutter={[24, 0]} style={{ marginTop: "1rem" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox cardbody h-full">
                    <Table
                      size="small"
                      columns={columns}
                      dataSource={AllEmployee}
                      rowKey="empcode"
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Space>
        </div>
      </div>
      {openModalManage && ModalManage()}
    </>
  );
}

export default Employee;
