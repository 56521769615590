/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Flex } from "antd";
import { Row, Col, Space, Select, Badge } from "antd";

import { SaveFilled } from "@ant-design/icons";

const ModalProductSpecManage = ({ submit, source = null, action }) => {
  const [form] = Form.useForm();

  const [formDetail, setFormDetail] = useState();

  const init = async () => {
    if (!!source) {
      const initForm = { ...source };
      setFormDetail((state) => ({ ...state, ...initForm }));
      form.setFieldsValue(initForm);
    }
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const handleConfirm = () => {
    form.validateFields().then((v) => {
      const source = { ...formDetail, ...v };
      submit(source);
    });
  };

  const Detail = () => (
    <Row gutter={[324, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item
          label="Product Spec Name"
          name="spec_name"
          rules={[{ required: true, message: "Please enter data!" }]}
        >
          <Input
            placeholder="Enter Product Spec Name."
            className="!bg-zinc-300"
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item
          label="Product Spec Type"
          name="spec_type"
          rules={[{ required: true, message: "Please enter data!" }]}
        >
          <Select
            size="large"
            options={[
              {
                value: "Raw",
                label: "Raw",
              },
              {
                value: "Cooked",
                label: "Cooked",
              },
            ]}
          />
        </Form.Item>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        style={action === "edit" ? { display: "inline" } : { display: "none" }}
      >
        <Form.Item label="สถานการใช้งาน" name="active_status">
          <Select
            size="large"
            options={[
              {
                value: "Y",
                label: <Badge status="success" text="เปิดการใช้งาน" />,
              },
              {
                value: "N",
                label: <Badge status="error" text="ปิดการใช้งาน" />,
              },
            ]}
          />
        </Form.Item>
      </Col>
    </Row>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start"></Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  return (
    <div className="customer-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      <Space direction="vertical" className="flex gap-2">
        <Form form={form} layout="vertical" autoComplete="off">
          <br />
          <br />
          <Detail />
        </Form>
        {SectionBottom}
      </Space>
    </div>
  );
};

export default ModalProductSpecManage;
