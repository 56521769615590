import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Unit from "../pages/Unit";
import Login from "../pages/Login";
// import Peelshrimp from "../pages/Peelshrimp";
import Weightdata from "../pages/Weightdata";
import PageNotFound from "../pages/404";
import User from "../pages/User";


import PrivateRoute from "../components/auth/PrivateRoutes"; 

import Employee from "../pages/Employee";
import { Product, ProductAccess, ProductManage } from "../pages/product";
import { Peelshrimp, PeelshrimpAccess, PeelshrimpManage } from "../pages/report_peelshrimp";

import { 
  PeelshrimpPrintPreview,
} from "../components/print";
import { ROLES, LAYOUT } from "../constant/constant";

const Router = () => {
  return (
    
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />

            <Route  element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} />} >

              <Route path="/unit" element={<Unit />} />
              <Route path="/weightdata" element={<Weightdata />} />    

              <Route path="/report_peelshrimp/"  exact element={<Peelshrimp />} >
                <Route index element={<PeelshrimpAccess />} />
                <Route path="manage/:action" element={<PeelshrimpManage />} />                
              </Route> 

              <Route path="/product/"  exact element={<Product />} >
                <Route index element={<ProductAccess />} />
                <Route path="manage/:action" element={<ProductManage />} />                
              </Route> 

            </Route>

            <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} layout={LAYOUT.ALOND} />}>
              <Route path="/peelshrimp-print/:code/:firstdate/:lastdate?" element={<PeelshrimpPrintPreview />} />
            </Route>


            <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN]} />}>
              <Route path="/user" element={<User/>} />
              <Route path="/employee" element={<Employee/>} />
            </Route>

            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>        


  );
};
 
export default Router;
