import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import logo from "../../../assets/images/logo_yn.png";
import "./peelshrimp.css";
import { format } from 'date-fns';
// import dayjs from "dayjs";
import { spColumnsView } from "./peelshrimp.model";

import PeelshrimpService from "../../../service/Peelshrimp.service";
import { Button, Card, Space, Table, Typography,Row,Col } from "antd";
import { formatCommaNumber } from "../../../utils/util";
import { PiPrinterFill } from "react-icons/pi";
const psService = PeelshrimpService();
function SptPrintPreview() {
  const { code,firstdate,lastdate } = useParams();
  const componentRef = useRef(null);
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => handleBeforePrint(),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });
  const initialValues = {code:code,
    firstdate:firstdate,
    lastdate:lastdate};
  const [header, setHeader] = useState([]);
  const [stepData, setStepData] = useState([]);
  // const [parmData, setParmData] = useState([]);
  const formData = (initialValues);

  const handleBeforePrint = (e) => {
    // const newElement = document.createElement('div');
    // newElement.id = 'new-container'; // Optional: Set an ID for the new container
    // newElement.innerHTML = 'TEST';
    // Render the new component into the new container
    // Replace the old container with the new container
    // componentRef.current.innerHTML = 'TEST';
  };

  const handleCheckMultiPages = () => {
    // const limitPage = 900;

    // const head = document.getElementById("form-head");
    // const step = document.getElementById("form-body-step");
    // const parm = document.getElementById("form-body-parm");

    // const headHieght = Number(
    //   window
    //     .getComputedStyle(head)
    //     .getPropertyValue("height")
    //     ?.replace("px", "")
    // );
    // const stepHieght = Number(
    //   window
    //     .getComputedStyle(step)
    //     .getPropertyValue("height")
    //     ?.replace("px", "")
    // );
    
    // const parmHieght = Number(
    //   window
    //     .getComputedStyle(parm)
    //     .getPropertyValue("height")
    //     ?.replace("px", "")
    // );
    // console.log({ headHieght, stepHieght, parmHieght });

    // if (headHieght + stepHieght > limitPage) {
    //   parm.style.pageBreakBefore = "always";
    // }

    // style="page-break-after: always"
    printRef.current = componentRef.current;

    return printRef.current;
  };

  const PrintComponent = () => {
    return (
      <div className="sp-page-form" ref={componentRef}>
        <table style={{ width: "100%", fontFamily: "inherit" }}>
          <thead>
            <tr>
              <th>
                <PrintHeaderPage />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr id="form-head">
              <td>
                <HeaderData />
              </td>
            </tr>
            <tr id="form-body-step">
              <td>
                <BodyData />
              </td>
            </tr>
            <tr id="form-body-parm">
              <td>
                <SummaryTotalData />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const HeaderData = () => {
    return (
      <div className="head-data">
        <div className="text-center">
          <Typography.Title level={3} className="uppercase mb-0.5">
            รายงานสรุปยอดค่าจ้างแกะกุ้งแยกตามพนักงาน
          </Typography.Title>
        </div>
        <div className="flex">
          <div className="flex w-1/2 flex-col">
            <Space className="w-full items-baseline">
              <Typography.Text
                strong
                style={{ display: "block", width: 128 }}
                width={124}
              >
                ตั้งแต่วันที่
              </Typography.Text>
              <Typography.Text>
                {
                format(firstdate, 'dd/MM/yyyy')}
              </Typography.Text>
            </Space>
            <Space className="w-full items-baseline" style={{ margin:"0 0 0 30px" }}>
              <Typography.Text
                strong
                style={{ display: "block", width: 128 }}
                width={124}
              >
                ถึงวันที่
              </Typography.Text>
              <Typography.Text>
                {format(lastdate, 'dd/MM/yyyy')}
              </Typography.Text>
            </Space>
          </div>
        </div>
      </div>
    );
  };

  const BodyData = () => {
    return (
      <div className="body-data">
        {stepData.map((step, ind) => (          
          <Card
            key={ind}
            title={
              <div>
                <Typography.Text strong className="uppercase text-white">
                {header[ind][0]?.empcode} {header[ind][0]?.fullname}        
                </Typography.Text>
              </div>
            }
            bordered={false}
          >
            <Table
              columns={spColumnsView}
              dataSource={step}
              pagination={false}
              bordered={false}
              rowKey="id"
              summary={(_) => <SummaryStepData data={step} />}
            />
          </Card>
        ))}
      </div>
    );
  };

  const SummaryTotalData = () => {
    return (
      <div className="body-data">
        <Card
          title={
            <div>
              <Row gutter={[8, 8]}>
                  <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    ยอดรวม
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  {formatCommaNumber(totalIncome(),2)}
                  </Col>
                </Row>
            </div>
          }
          bordered={false}
        >
        </Card>
      </div>
    );
  };

  const totalIncome = () => {
    let sum =0;
    stepData.map((step, ind) => (
        sum+= Object.values(stepData[ind]).reduce((total, v) => total + (v.cost*v.unit_weight), 0)
      ))
    // console.log(stepData)
    return sum
  }

  const SummaryStepData = ({ data }) => {
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={7}>
            ยอดรวม
          </Table.Summary.Cell>
          <Table.Summary.Cell
            index={1}
            colSpan={2}
            className="pe-2 text-end border-right-0"
            style={{ borderRigth: "0px solid" }}
          >
            <Typography.Text
              type="danger"
              className="underline decoration-double underline-offset-2"
              style={{ fontSize: "10pt" }}
            >
              {formatCommaNumber((data.reduce((a,v) =>  a += (v.cost*v.unit_weight) , 0 )))}
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const PrintHeaderPage = () => {
    return (
      <div
        className="flex width-100 items-end head-page pt-4"
        style={{ height: 32 }}
      >
        <div className="flex w-full">
          <img
            src={logo}
            alt=""
            style={{ paddingInline: 6, paddingBlock: 3, width: 64 }}
          />
        </div>
        <div className="flex w-full">
          {/* <Space className='w-full items-baseline justify-end' >
                        <Typography.Text strong style={{ fontSize:'9pt'}}>Form Date</Typography.Text>
                        <Typography.Text style={{ fontSize:'9pt'}}>{dayjs().format("MMM DD, YYYY")}</Typography.Text>
                    </Space> */}
        </div>
      </div>
    );
  };

  useEffect(() => {

    const init = () => {        

        psService.get(formData).then(async (res) => {
        const data  = res.data.data;
        const header = res.data.header;
        
        
        
        const step = [...new Set(data.map((d) => d.empcode))];

        const arrData = [];
        const arrData2 = [];

        for (let s of step) arrData.push(data.filter((d) => d.empcode === s));
        for (let s of step) arrData2.push(header.filter((d) => d.empcode === s));

        // console.log(arrData)
        // console.log(arrData2)

        setHeader(arrData2)
        setStepData(arrData);

      });
    };
    init();
  }, []);

  return (
    <div className="page-show" id="spt">
      <div className="title-preview">
        <Button
          className="bn-center"
          onClick={() => {
            handlePrint(null, () => handleCheckMultiPages());
          }}
          icon={<PiPrinterFill style={{ fontSize: "1.1rem" }} />}
        >
          PRINT
        </Button>
      </div>
      <div className="print-layout-page">
        <PrintComponent />
      </div>
      <div className="hidden">
        <div ref={printRef}></div>
      </div>
    </div>
  );
}

export default SptPrintPreview;
