import { useState } from "react";
import { Authenticate } from "../../service/Authenticate.service";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "react-pro-sidebar";
import { PoweroffOutlined } from "@ant-design/icons";
import { Layout, Button, Modal, } from "antd";
import logo from "../../assets/images/logo_yn.png";
import nav from "../../nav";
const { Footer } = Layout;
const authService = Authenticate();
const Sidenav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onLogout = () => {
    authService.removeToken();
    navigate("/", { replace: true });
  };
  const navActiveStyle = {
    padding: "10px 16px",
    color: "#141414",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 20px 27px rgba(0,0,0,.05)",
  };
  const [isModalOpenClose, setIsModalOpenClose] = useState(false);
  const showModalClose = () => {
    setIsModalOpenClose(true);
  };
  const handleOkClose = () => {
    onLogout();
  };
  const handleCancelClose = () => {
    setIsModalOpenClose(false);
  };
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Yeenin Frozen Food</span>
      </div>
      <hr />

      
      <Menu theme="light" mode="inline">
      {nav.filter( (item) => {
            return ( !item.role || !!item?.role?.includes( authService.getType() ) );
          }).map((item, idx) => {
            return ( !item?.type ? (
              <MenuItem
                icon={item?.icon}
                key={idx}
                component={ <Link to={item?.to} style={{navActiveStyle}} /> }
                className={pathname.startsWith(item?.to)? "nav-active" : null}
              > 
                {item?.title}
              </MenuItem>
            ) : (
              <MenuItem key={idx} className="nav-group-title">
                {item?.title}
              </MenuItem>
            )
            );
          })}
      </Menu>      
      <hr />
      <Footer>
        <Button
          onClick={showModalClose}
          className="btn-sign-in"
          icon={
            <PoweroffOutlined
              style={{
                fontSize: "16px",
              }}
            />
          }
          style={{ border: "none", backgroundColor: "inherit" }}
        >
          <span>Log out</span>
        </Button>
        <Modal
          title="แจ้งเตือน"
          open={isModalOpenClose}
          onOk={handleOkClose}
          onCancel={handleCancelClose}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <p>คุณแน่ใจหรือไม่ว่าต้องการออก</p>
        </Modal>
      </Footer>
    </>
  );
};

export default Sidenav;
