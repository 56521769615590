/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Button, Flex, InputNumber,Select,Badge } from "antd";
import { Row, Col, Space } from "antd";

import { SaveFilled } from "@ant-design/icons";

const ModalProductSizeManage = ({ submit, source = null, action }) => {
  const [form] = Form.useForm();

  const [formDetail, setFormDetail] = useState();

  const init = async () => {
    if (!!source) {
      const initForm = { ...source };
      setFormDetail((state) => ({ ...state, ...initForm }));
      form.setFieldsValue(initForm);
    }
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const handleConfirm = () => {
    form.validateFields().then((v) => {
      const source = { ...formDetail, ...v };
      submit(source);
    });
  };

  const Detail = () => (
    <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
      <Col span={24}>
        <Form.Item
          label="Product Size Min"
          name="size_min"
          rules={[{ required: true, message: "Please enter data!" }]}
        >
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            size="large"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Product Size Max"
          name="size_max"
          rules={[{ required: true, message: "Please enter data!" }]}
        >
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            size="large"
          />
        </Form.Item>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
        style={action === "edit" ? { display: "inline" } : { display: "none" }}
      >
        <Form.Item label="สถานการใช้งาน" name="active_status">
          <Select
            size="large"
            options={[
              {
                value: "Y",
                label: <Badge status="success" text="เปิดการใช้งาน" />,
              },
              {
                value: "N",
                label: <Badge status="error" text="ปิดการใช้งาน" />,
              },
            ]}
          />
        </Form.Item>
      </Col>
    </Row>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          {/* <ButtonBack target={from} /> */}
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  return (
    <div className="customer-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      <Space style={{width:"100%"}} direction="vertical" className="flex gap-2">
        <Form form={form} layout="vertical" autoComplete="off">
          <br />
          <br />
          <Detail />
        </Form>
        {SectionBottom}
      </Space>
    </div>
  );
};

export default ModalProductSizeManage;
