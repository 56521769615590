/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Card, message } from "antd";
import {
  Collapse,
  Form,
  Flex,
  Row,
  Col,
  Space,
  Input,
  Modal,
  DatePicker,
} from "antd";
import { Button, Table, Typography } from "antd";
import { SearchOutlined, ClearOutlined,PrinterOutlined } from "@ant-design/icons";
// import { MdGroupAdd } from "react-icons/md";
import { accessColumn } from "./peelshrimp.model.js";
import dayjs from "dayjs";
import OptionsService from "../../service/Options.service.js";
const opService = OptionsService();

const Report_peelshrimpAccess = () => {
  const [form] = Form.useForm();

  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";

  const [accessData, setAccessData] = useState([]);
  const [activeSearch, setActiveSearch] = useState([]);
  const [pickedDates, setPickedDates] = useState([dayjs(), dayjs()]);
  const [formModal] = Form.useForm();
  const [openModalDate, setOpenModalDate] = useState(false);

  const handleSearch = () => {
    form.validateFields().then((v) => {
      const data = { ...v };
      getData(data);
    });
  };

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const handlePrint = () => {
    // alert(pickedDates[0])
    // alert(pickedDates[1])
    // alert(pickedDates[1].format("YYYY%2FMM%2FDD"))
    const newWindow = window.open("", "_blank");
    let firstdate = pickedDates[0].format("YYYY%2FMM%2FDD");
    let lastdate = pickedDates[1].format("YYYY%2FMM%2FDD");
    // let firstdate = pickedDates[0];
    // let lastdate = pickedDates[1];
    let empcode = formModal.getFieldValue("empcode");

    newWindow.location.href = `/peelshrimp-print/${empcode}/${firstdate}/${lastdate}`;
  };

  const handleView = (data) => {
    setOpenModalDate(true);

    formModal.setFieldValue("empcode", data);
  };
  const handleDateChange = (value) => {
    setPickedDates(value);
  };
  // const handleDateChange = (dates, dateString) => {
  //     alert(dateString[1])
  //     // alert(dayjs(format(dateString[0], 'yyyy/dd/MM')))
  //   if (dates) {
  //     dayjs.extend(customParseFormat)

  //     let startDate = dayjs(dayjs(dateString[0], "MM-DD-YYYY"));
  //     let endDate = dayjs(format(dateString[1], 'yyyy/dd/MM'));
  //     // alert(endDate)
  //     setPickedDates([startDate, endDate]);
  //   }
  // };

  const getData = (data) => {
    opService
      .optionsEmployee(data)
      .then((res) => {
        const { data } = res.data;
        // console.log(data)

        setAccessData(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  useEffect(() => {
    getData({});
  }, []);

  const FormSearch = (
    <Collapse
      size="small"
      onChange={(e) => {
        setActiveSearch(e);
      }}
      activeKey={activeSearch}
      items={[
        {
          key: "1",
          label: (
            <>
              <SearchOutlined />
              <span> Search</span>
            </>
          ),
          children: (
            <>
              <Form form={form} layout="vertical" autoComplete="off">
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item label="รหัสพนักงาน" name="empcode">
                      <Input
                        placeholder="รหัสพนักงาน"
                        onChange={handleSearch}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item label="ชื่อจริง" name="firstname">
                      <Input placeholder="ชื่อจริง" onChange={handleSearch} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8} lg={8}>
                    <Form.Item label="นามสกุล" name="lastname">
                      <Input placeholder="นามสกุล" onChange={handleSearch} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    {/* Ignore */}
                  </Col>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    <Flex justify="flex-end" gap={8}>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        icon={<SearchOutlined />}
                        onClick={() => handleSearch()}
                      >
                        Search
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        danger
                        icon={<ClearOutlined />}
                        onClick={() => handleClear()}
                      >
                        Clear
                      </Button>
                    </Flex>
                  </Col>
                </Row>                
              </Form>
            </>
          ),
          showArrow: false,
        },
      ]}
      // bordered={false}
    />
  );
  const column = accessColumn({ handleView });

  const TitleTable = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            Report Peelshrimp
          </Typography.Title>
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            size="small"
            className="bn-action bn-center bn-primary-outline justify-center"
            icon={<PrinterOutlined style={{ fontSize: ".9rem" }} />}
            onClick={() => {
              handleView("ALL");
            }}
          >
            ปริ้นทั้งหมด
          </Button>
        </Flex>
      </Col>
    </Flex>
  );
  return (
    <div className="pilot-scale-access" style={{ padding: 20 }}>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", position: "relative" }}
      >
        {FormSearch}
        <Card>
          <Row gutter={[8, 8]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                title={() => TitleTable}
                size="small"
                rowKey="empcode"
                columns={column}
                dataSource={accessData}
              />
            </Col>
          </Row>
        </Card>
      </Space>

      <Modal
        title="กรุณาเลือกวันที่"
        open={openModalDate}
        onOk={handlePrint}
        onCancel={() => setOpenModalDate(false)}
      >
        <Form
          form={formModal}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Row gutter={[8, 8]} className="m-0">
            <Form.Item label="รหัสพนักงาน" name="empcode">
              <Input placeholder="รหัสพนักงาน" disabled />
            </Form.Item>
          </Row>
          <Row gutter={[8, 8]} className="m-0">
            <RangePicker
              defaultValue={[dayjs(), dayjs()]}
              onChange={handleDateChange}
              format={dateFormat}
            />
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Report_peelshrimpAccess;
