import { requestService as api } from "./Request.service";
import { STORE_KEY } from "../constant/constant";

const API_URL = { 
    API_MANAGE: `/reports/totalpeelshrimp.php`,
};

const getHeader = () => {
  const t = sessionStorage.getItem(STORE_KEY.authen);

  return {
    // "content-type" : "application/x-www-form-urlencoded",
    "Authorization" : `Bearer ${t}`
  }
}

const PeelshrimpService = () => { 
  
  // const get = (code=null) => api.post(API_URL.API_MANAGE+`?code=${code}`, { headers: getHeader() });
  const get = (parm = {}) => api.post(`${API_URL.API_MANAGE}`, parm, { headers: getHeader() });

  return {
    get,
  };
};

export default PeelshrimpService;