import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
// import {render} from 'react-dom'
// import { useReactToPrint } from "react-to-print";

import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Space,
  Table,
  Row,
  Col,
  Card,
  Modal,
  Form,
  Select,
  Collapse,
  Flex,
  message,
  DatePicker,
} from "antd";
import Swal from "sweetalert2";
import WeightService from "../service/Weightdata.service";
import OptionsService from "../service/Options.service.js";
import dayjs from "dayjs";
import { delay } from "../utils/util";
const RangePicker = DatePicker.RangePicker;
// import  PrintPdf  from "../utils/exportpdf";
const weightService = WeightService();
const opService = OptionsService();

function Weight() {
  const [accessData, setAccessData] = useState([]);
  const [OpenModalEdit, setOpenModalEdit] = useState(false);
  const [option, setOption] = useState([]);
  const [optionValue, setOptionValue] = useState();
  const [formEdit] = Form.useForm();
  const [optionSpec, setOptionSpec] = useState([]);
  const [optionSize, setOptionSize] = useState([]);

  const [form] = Form.useForm();

  const [activeSearch, setActiveSearch] = useState([]);

  const init = async () => {};

  useEffect(() => {
    init();
    getlistOption();
    getData({});
    GetProduct();
    return async () => {
      await delay(400);
      // console.clear();
    };
  }, []);

  const handleSearch = () => {
    form
      .validateFields()
      .then((v) => {
        const data = { ...v };
        if (!!data?.created_date) {
          const arr = data?.created_date.map((m) =>
            dayjs(m).format("YYYY-MM-DD")
          );
          const [created_form, created_to] = arr;
          //data.created_date = arr
          Object.assign(data, { created_form, created_to });
        }

        getData(data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getlistOption = async () => {
    await opService.optionsProductspec().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSpec(data);
    });

    await opService.optionsProductsize().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSize(data);
    });
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      hidden: "false",
      width: "10%",
    },
    {
      title: "ช่วงเวลา",
      dataIndex: "create_date",
      key: "create_date",
      width: "15%",
    },
    {
      title: "รหัสพนักงาน",
      dataIndex: "empcode",
      key: "empcode",
      width: "10%",
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "empname",
      key: "empname",
      width: "15%",
    },
    {
      title: "น้ำหนัก",
      dataIndex: "unit_weight",
      key: "unit_weight",
      width: "10%",
    },
    {
      title: "จำนวนเปลือกกุ้ง",
      dataIndex: "shrimp_shell",
      key: "shrimp_shell",
      width: "10%",
    },      
    {
      title: "Product Name",
      dataIndex: "spec_name",
      key: "spec_name",
      width: "10%",
    },
    {
      title: "Product Type",
      dataIndex: "spec_type",
      key: "spec_type",
      width: "10%",
    },
    {
      title: "Product Size",
      dataIndex: "productsizeminmax",
      key: "productsizeminmax",
      width: "15%",
    },
    {
      title: "Action",
      key: "operation",
      width: "15%",
      fixed: "right",
      render: (text) => (
        <Button
          size="small"
          icon={
            <EditOutlined
              twoToneColor="#E74C3C"
              style={{ fontSize: ".9rem" }}
            />
          }
          danger
          onClick={(e) => onClickEdit(text.id)}
        ></Button>
      ),
    },
  ].filter((item) => !item.hidden);

  const getData = (data) => {
    weightService
      .search(data)
      .then((res) => {
        // const { data } = res.data;
        const { data } = res.data;

        setAccessData(data);

        // setAccessData(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const GetProduct = () => {
    opService.optionsProduct().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOption(data);
    });
  };

  const showEditModal = (id) => {
    weightService
      .get(id)
      .then((res) => {
        const { data } = res.data;
        // console.log(data)
        //console.log(data)
        formEdit.setFieldValue("Editid", data.id);
        formEdit.setFieldsValue(data);

        setOpenModalEdit(true);
      })
      .catch((err) => {});
  };

  const submitEdit = (dataform) => {
    weightService
      .update(dataform)
      .then(async (res) => {
        const { data } = res.data;

        if (data.status) {
          await Swal.fire({
            title: "<strong>สำเร็จ</strong>",
            html: data.message,
            icon: "success",
          });

          getData({});
        } else {
          // alert(data.message)
          Swal.fire({
            title: "<strong>ผิดพลาด!</strong>",
            html: data.message,
            icon: "error",
          });
        }
      })
      .catch((err) => {});
  };

  const onClickEdit = (value) => {
    showEditModal(value);
  };

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const CollapseItemSearch = () => {
    return (
      <>
        <Row gutter={[8, 8]}>
          <Col xs={24} md={6} lg={6}>
            <Form.Item label="รหัสพนักงาน" name="empcode">
              <Input placeholder="รหัสพนักงาน" onChange={handleSearch} />
            </Form.Item>
          </Col>
          <Col xs={24} md={5} lg={5}>
            <Form.Item label="ชื่อจริง" name="firstname">
              <Input placeholder="ชื่อจริง" onChange={handleSearch} />
            </Form.Item>
          </Col>
          <Col xs={24} md={5} lg={5}>
            <Form.Item label="นามสกุล" name="lastname">
              <Input placeholder="นามสกุล" onChange={handleSearch} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item label="วันที่" name="created_date">
              <RangePicker
                placeholder={["From Date", "To date"]}
                style={{ width: "100%", height: 40 }}
                onChange={handleSearch}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item label="Spec Name." name="product_spec_id">
              <Select
                placeholder="Enter Spec Name."
                style={{ height: 38 }}
                onChange={handleSearch}
                options={optionSpec.map((item) => ({
                  value: item.spec_id,
                  label: item.spec_name + " / " + item.spec_type,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item label="Spec Type." name="spec_type">
              <Select
                placeholder="Enter Spec Type."
                style={{ height: 38 }}
                onChange={handleSearch}
                options={[
                  {
                    value: "Raw",
                    label: "Raw",
                  },
                  {
                    value: "Cooked",
                    label: "Cooked",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} lg={8}>
            <Form.Item label="Product Size." name="product_size_id">
              <Select
                placeholder="Enter Product Size."
                style={{ height: 38 }}
                onChange={handleSearch}
                filterOption={filterOption}
                options={optionSize.map((item) => ({
                  value: item.productsizeid,
                  label: item.size_min + "/" + item.size_max,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={8} md={12} lg={12} xl={12}>
            {/* Ignore */}
          </Col>
          <Col xs={24} sm={8} md={12} lg={12} xl={12}>
            <Flex justify="flex-end" gap={8}>
              <Button
                type="primary"
                size="small"
                className="bn-action"
                icon={<SearchOutlined />}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
              <Button
                type="primary"
                size="small"
                className="bn-action"
                danger
                icon={<ClearOutlined />}
                onClick={() => handleClear()}
              >
                Clear
              </Button>
            </Flex>
          </Col>
        </Row>
      </>
    );
  };

  const FormSearch = (
    <Collapse
      size="small"
      onChange={(e) => {
        setActiveSearch(e);
      }}
      activeKey={activeSearch}
      items={[
        {
          key: "1",
          label: (
            <>
              <SearchOutlined />
              <span> Search</span>
            </>
          ),
          children: CollapseItemSearch(),
          showArrow: false,
        },
      ]}
      // bordered={false}
    />
  );

  ////////////////////////////////

  return (
    <>
      <div className="layout-content">
        {/* modal edit */}
        <Modal
          open={OpenModalEdit}
          title="แก้ไขน้ำหนักชั่งกุ้ง"
          okText="Update"
          cancelText="Cancel"
          onCancel={() => setOpenModalEdit(false)}
          // width={1000}
          onOk={() => {
            formEdit
              .validateFields()
              .then((values) => {
                // formEdit.resetFields();
                // console.log(values)

                submitEdit(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form
            form={formEdit}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Row gutter={[12, 0]}>
              <Form.Item name="id" noStyle>
                {/* <Input  type="hidden" /> */}
              </Form.Item>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                รหัสพนักงาน
                <Form.Item
                  name="empcode"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาใส่รหัสผู้ใช้!",
                    },
                  ]}
                >
                  <Input placeholder="กรุณาใส่รหัสพนักงาน" readOnly />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                น้ำหนักที่ชั่งได้
                <Form.Item
                  name="unit_weight"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาใส่ unit weight ",
                    },
                  ]}
                >
                  <Input placeholder="unit weight" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                จำนวนเปลือกกุ้ง
                <Form.Item
                  name="shrimp_shell"
                >
                  <Input placeholder="จำนวนเปลือกกุ้ง" />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              สถานะ
              <Form.Item name="active_status">
                <Select
                  style={{ height: 40 }}
                  options={[
                    { value: "Y", label: "เปิดใช้งาน" },
                    { value: "N", label: "ปิดใช้งาน" },
                  ]}
                />
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                Product
                <Form.Item
                  name="product_id"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุประเภท!",
                    },
                  ]}
                >
                  <Select
                    style={{ height: 40 }}
                    value={optionValue}
                    onChange={(value) => setOptionValue(value)}
                    options={option.map((item) => ({
                      value: item.product_id,
                      label: item.productname,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                เวลา
                <Form.Item name="create_date">
                  <Input placeholder="" readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <div className="pilot-scale-access" id="area" style={{ padding: 20 }}>
          <Space
            direction="vertical"
            size="middle"
            style={{ display: "flex", position: "relative" }}
          >
            <Form form={form} layout="vertical" autoComplete="off">
              {FormSearch}
            </Form>
            <Card>
              <Row gutter={[24, 0]} style={{ marginTop: "1rem" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                  <Card bordered={false} className="criclebox cardbody h-full">
                    <Table
                      size="small"
                      columns={columns}
                      dataSource={accessData}
                      rowKey="id"
                    />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Space>
        </div>
      </div>
    </>
  );
}

export default Weight;
