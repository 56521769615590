import { Badge, Space } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"; 
import { EditOutlined } from "@ant-design/icons"; 

export const accessColumn = ({handleEdit, handleView}) => [
    {
      title: "Product Code.",
      key: "product_id",
      dataIndex: "product_id",
      align: "left",
      width: '14%',
      hidden: true
    },
    {
      title: "Spec Name.",
      dataIndex: "spec_name",
      key: "spec_name",
      width: '14%',
    },
    {
      title: "Spec Type.",
      dataIndex: "spec_type",
      key: "spec_type",
      width: '14%',
    },
    {
      title: "Size Min.",
      dataIndex: "size_min",
      key: "size_min",
      width: '14%',
    },
    {
      title: "Size Max.",
      dataIndex: "size_max",
      key: "size_max",
      width: '14%',
    },
    {
      title: "ราคาค่าแกะ /kg",
      dataIndex: "cost",
      key: "cost",
      width: '14%',
    },
    {
      title: "Status",
      dataIndex: "active_status",
      key: "active_status",
      width: '14%',
      render: (data) => (
        <div>
          {data === "Y" ? <Badge status="success" text="เปิดการใช้งาน" /> : <Badge status="error" text="ปิดการใช้การ" />}
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: '14%',
      fixed: 'right',
      render: (text, record) => (
        <Space > 
          <Button
            icon={<EditOutlined />} 
            className='bn-primary-outline'
            style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={(e) => handleEdit(record) }
            size="small"
          />
        </Space>
      ),
    },    
]; 

export const accessColumnSpec = ({handleEditSpec, handleView}) => [
  {
    title: "Spec ID",
    key: "spec_id",
    dataIndex: "spec_id",
    align: "left",
    width: '25%',
    hidden: true
  },
  {
    title: "Spec Name",
    dataIndex: "spec_name",
    key: "spec_name",
    width: '25%',
  },
  {
    title: "Spec Type.",
    dataIndex: "spec_type",
    key: "spec_type",
    width: '25%',
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: '25%',
    render: (data) => (
      <div>
        {data === "Y" ? <Badge status="success" text="เปิดการใช้งาน" /> : <Badge status="error" text="ปิดการใช้การ" />}
      </div>
    ),
  },
  {
    title: "Action",
    key: "operation",
    width: '15%',
    fixed: 'right',
    render: (text, record) => (
      <Space > 
        <Button
          icon={<EditOutlined />} 
          className='bn-primary-outline'
          style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          onClick={(e) => handleEditSpec(record) }
          size="small"
        />
      </Space>
    ),
  },    
]; 

export const accessColumnSize = ({handleEditSize, handleView}) => [
  {
    title: "Product Size Id.",
    key: "productsizeid",
    dataIndex: "productsizeid",
    align: "left",
    width: '25%',
    hidden: true
  },
  {
    title: "Size Min",
    dataIndex: "size_min",
    key: "size_min",
    width: '25%'
  },
  {
    title: "Size Max",
    dataIndex: "size_max",
    key: "size_max",
    width: '25%' 
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: '25%',
    render: (data) => (
      <div>
        {data === "Y" ? <Badge status="success" text="เปิดการใช้งาน" /> : <Badge status="error" text="ปิดการใช้การ" />}
      </div>
    ),
  },
  {
    title: "Action",
    key: "operation",
    width: '15%',
    fixed: 'right',
    render: (text, record) => (
      <Space > 
        <Button
          icon={<EditOutlined />} 
          className='bn-primary-outline'
          style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
          onClick={(e) => handleEditSize(record) }
          size="small"
        />
      </Space>
    ),
  },    
]; 
// export const product = {
//     product_id: null,
//     product_spec_id: null,
//     product_size_id: null,
//     price: null,
//     active_status: null,
// }