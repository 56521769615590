/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, message } from "antd";
import {
  Collapse,
  Form,
  Flex,
  Row,
  Col,
  Space,
  Select,
  Drawer,
  InputNumber,
} from "antd";
import { Button, Table, Typography } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { MdGroupAdd } from "react-icons/md";
import {
  accessColumn,
  accessColumnSpec,
  accessColumnSize,
} from "./product.model.js";
import { ModalProductSpecManage } from "../../components/modal/product-spec/modal-product-spec.js";
import { ModalProductSizeManage } from "../../components/modal/product-size/modal-product-size.js";

// import dayjs from 'dayjs';
import ProductService from "../../service/Product.service.js";
import ProductSpecService from "../../service/ProductSpec.service.js";
import ProductSizeService from "../../service/ProductSize.service.js";
import OptionsService from "../../service/Options.service.js";

const productService = ProductService();
const productSpecService = ProductSpecService();
const productSizeService = ProductSizeService();
const opService = OptionsService();

const mngConfig = {
  title: "",
  textOk: null,
  textCancel: null,
  action: "create",
  code: null,
};
const ProductAccess = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [accessData, setAccessData] = useState([]);
  const [accessDataSpec, setAccessDataSpec] = useState([]);
  const [accessDataSize, setAccessDataSize] = useState([]);
  const [activeSearch, setActiveSearch] = useState([]);

  const [actionManage, setActionManage] = useState({
    action: "create",
    title: "Create Product",
    confirmText: "Create",
  });

  const [sourceSpec, setSourceSpec] = useState(null);
  const [sourceSize, setSourceSize] = useState(null);

  const [openManageSpec, setOpenManageSpec] = useState(false);
  const [openManageSize, setOpenManageSize] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [optionSpec, setOptionSpec] = useState([]);
  const [optionSize, setOptionSize] = useState([]);

  const handleSearch = () => {
    form.validateFields().then((v) => {
      const data = { ...v };
      getData(data);
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const handleEdit = (data) => {
    navigate("manage/edit", {
      state: {
        config: {
          ...mngConfig,
          title: "Edit Product",
          action: "edit",
          code: data?.product_id,
        },
      },
      replace: true,
    });
  };

  const getlistOption = async () => {
    await opService.optionsProductspec().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSpec(data);
    });

    await opService.optionsProductsize().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSize(data);
    });
  };

  const handleEditSpec = (data) => {
    setActionManage({
      action: "edit",
      title: "Edit Product Spec",
      confirmText: "Edit",
    });

    productSpecService
      .get(data?.spec_id)
      .then(async (res) => {
        const { data } = res.data;

        setSourceSpec(data);

        setOpenManageSpec(true);
      })
      .catch((err) => {
        // console.warn(err);
        const { data } = err.response;
        message.error(data?.message || "error request");
      });
  };

  const handleEditSize = (data) => {
    setActionManage({
      action: "edit",
      title: "Edit Product Size",
      confirmText: "Edit",
    });

    productSizeService
      .get(data?.productsizeid)
      .then(async (res) => {
        const { data } = res.data;

        setSourceSize(data);

        setOpenManageSize(true);
      })
      .catch((err) => {
        // console.warn(err);
        const { data } = err.response;
        message.error(data?.message || "error request");
      });
  };

  const handleView = (data) => {
    const newWindow = window.open("", "_blank");
    newWindow.location.href = `/dln-print/${data.dncode}`;
  };

  const getData = (data) => {
    productService
      .search(data)
      .then((res) => {
        const { data } = res.data;
        // console.log(data)

        setAccessData(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const getDataSpec = (data) => {
    productSpecService
      .search(data)
      .then((res) => {
        const { data } = res.data;

        setAccessDataSpec(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  const getDataSize = (data) => {
    productSizeService
      .search(data)
      .then((res) => {
        const { data } = res.data;

        setAccessDataSize(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Request error!");
      });
  };

  useEffect(() => {
    getData({});
    getDataSpec({});
    getDataSize({});
    getlistOption();
    handleResize(); // Set initial screen size

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
  };

  const hangleAdd = () => {
    navigate("manage/create", {
      state: {
        config: {
          ...mngConfig,
          title: "Create Product",
          action: "create",
        },
      },
    });
  };

  const hangleAddSpec = () => {
    setActionManage({
      action: "create",
      title: "Create Product Spec",
      confirmText: "Create",
    });
    setSourceSpec(null);
    setOpenManageSpec(true);
  };

  const hangleAddSize = () => {
    setActionManage({
      action: "create",
      title: "Create Product Size",
      confirmText: "Create",
    });
    setSourceSize(null);
    setOpenManageSize(true);
  };

  const manageSubmitSpec = (v) => {
    const action =
      actionManage?.action !== "create"
        ? productSpecService.update
        : productSpecService.create;

    action({ ...v })
      .then((_) => {
        getDataSpec({});
      })
      .catch((err) => {
        console.warn(err);
        const data = err?.response?.data;
        message.error(data?.message || "error request");
      })
      .finally(() => {
        actionManage?.action !== "create"
          ? message.success(`แก้ไข Product Spec สำเร็จ`)
          : message.success(`เพิ่ม Product Spec สำเร็จ`);

        setOpenManageSpec(false);
      });
  };

  const manageSubmitSize = (v) => {
    const action =
      actionManage?.action !== "create"
        ? productSizeService.update
        : productSizeService.create;

    action({ ...v })
      .then((_) => {
        getDataSize({});
      })
      .catch((err) => {
        console.warn(err);
        const data = err?.response?.data;
        message.error(data?.message || "error request");
      })
      .finally(() => {
        actionManage?.action !== "create"
          ? message.success(`แก้ไข Product Size สำเร็จ`)
          : message.success(`เพิ่ม Product Size สำเร็จ`);

        setOpenManageSize(false);
      });
  };

  const FormSearch = (
    <Collapse
      size="small"
      onChange={(e) => {
        setActiveSearch(e);
      }}
      activeKey={activeSearch}
      items={[
        {
          key: "1",
          label: (
            <>
              <SearchOutlined />
              <span> Search</span>
            </>
          ),
          children: (
            <>
              <Form form={form} layout="vertical" autoComplete="off">
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={6} lg={6}>
                    <Form.Item label="Spec Name." name="product_spec_id">
                      <Select
                        placeholder="Enter Spec Name."
                        style={{ height: 38 }}
                        onChange={handleSearch}
                        options={optionSpec.map((item) => ({
                          value: item.spec_id,
                          label: item.spec_name + " / " + item.spec_type,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6} lg={6}>
                    <Form.Item label="Spec Type." name="spec_type">
                      <Select
                        placeholder="Enter Spec Type."
                        style={{ height: 38 }}
                        onChange={handleSearch}
                        options={[
                          {
                            value: "Raw",
                            label: "Raw",
                          },
                          {
                            value: "Cooked",
                            label: "Cooked",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6} lg={6}>
                    <Form.Item label="Product Size." name="product_size_id">
                      <Select
                        placeholder="Enter Product Size."
                        style={{ height: 38 }}
                        onChange={handleSearch}
                        filterOption={filterOption}
                        options={optionSize.map((item) => ({
                          value: item.productsizeid,
                          label: item.size_min + "/" + item.size_max,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6} lg={6}>
                    <Form.Item label="ราคาค่าแกะ /kg." name="cost" onChange={handleSearch}>
                    <InputNumber
                        min={0}
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    {/* Ignore */}
                  </Col>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    <Flex justify="flex-end" gap={8}>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        icon={<SearchOutlined />}
                        onClick={() => handleSearch()}
                      >
                        Search
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        danger
                        icon={<ClearOutlined />}
                        onClick={() => handleClear()}
                      >
                        Clear
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </>
          ),
          showArrow: false,
        },
      ]}
      // bordered={false}
    />
  );
  const column = accessColumn({ handleEdit, handleView });
  const columnSpec = accessColumnSpec({
    handleEditSpec,
    handleView,
  });
  const columnSize = accessColumnSize({ handleEditSize, handleView });

  const TitleTable = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            List of Product
          </Typography.Title>
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            size="small"
            className="bn-action bn-center bn-primary-outline justify-center"
            icon={<MdGroupAdd style={{ fontSize: ".9rem" }} />}
            onClick={() => {
              hangleAdd();
            }}
          >
            Create Product
          </Button>
        </Flex>
      </Col>
    </Flex>
  );
  const TitleTableSpec = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            Product Spec
          </Typography.Title>
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            size="small"
            className="bn-action bn-center bn-primary-outline justify-center"
            icon={<MdGroupAdd style={{ fontSize: ".9rem" }} />}
            onClick={() => {
              hangleAddSpec();
            }}
          >
            Create Product Spec
          </Button>
        </Flex>
      </Col>
    </Flex>
  );
  const TitleTableSize = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            Product Size
          </Typography.Title>
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            size="small"
            className="bn-action bn-center bn-primary-outline justify-center"
            icon={<MdGroupAdd style={{ fontSize: ".9rem" }} />}
            onClick={() => {
              hangleAddSize();
            }}
          >
            Create Product Size
          </Button>
        </Flex>
      </Col>
    </Flex>
  );
  return (
    <div className="pilot-scale-access" style={{ padding: 20 }}>
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", position: "relative" }}
      >
        {FormSearch}
        <Card>
          <Row gutter={[8, 8]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                title={() => TitleTable}
                size="small"
                rowKey="product_id"
                columns={column}
                dataSource={accessData}
              />
            </Col>
          </Row>
        </Card>
      </Space>
      <Row gutter={[8, 8]} className="m-0">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <Row gutter={[8, 8]} className="m-0">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  title={() => TitleTableSpec}
                  size="small"
                  rowKey="spec_id"
                  columns={columnSpec}
                  dataSource={accessDataSpec}
                />
              </Col>
            </Row>
          </Card>
          {openManageSpec && (
            <Drawer
              destroyOnClose={true}
              title={actionManage.title}
              width={isSmallScreen ? "100%" : "30vw"}
              className="custom-drawer-class"
              onClose={() => {
                setOpenManageSpec(false);
              }}
              open={openManageSpec}
              styles={{ body: { padding: "0px 24px 8px" } }}
              getContainer={() => document.querySelector(".modal-customers")}
            >
              <ModalProductSpecManage
                submit={(val) => manageSubmitSpec(val)}
                source={sourceSpec}
                action={actionManage.action}
              />
            </Drawer>
          )}
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <Row gutter={[8, 8]} className="m-0">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  title={() => TitleTableSize}
                  size="small"
                  rowKey="productsizeid"
                  columns={columnSize}
                  dataSource={accessDataSize}
                />
              </Col>
            </Row>
          </Card>
          {openManageSize && (
            <Drawer
              destroyOnClose={true}
              title="Create Product Size"
              okText={actionManage.confirmText}
              width={isSmallScreen ? "100%" : "30vw"}
              className="custom-drawer-class"
              onClose={() => {
                setOpenManageSize(false);
              }}
              open={openManageSize}
              styles={{ body: { padding: "0px 24px 8px" } }}
              getContainer={() => document.querySelector(".modal-customers")}
            >
              <ModalProductSizeManage
                submit={(val) => manageSubmitSize(val)}
                source={sourceSize}
                action={actionManage.action}
              />
            </Drawer>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductAccess;
