import { requestService as api, getParmeter } from "./Request.service"  
const API_URL = {
  OPTION_PRODUCTSPEC: `/common/options-productspec.php`, 
  OPTION_PRODUCTSIZE: `/common/options-productsize.php`,
  OPTION_PRODUCT: `/common/options-product.php`,
  OPTION_Province: `/common/options-get_province.php`,
  OPTION_District: `/common/options-get_district.php`,
  OPTION_Subdistrict: `/common/options-get_subdistrict.php`,
  OPTION_Zipcode: `/common/options-get_zipcode.php`,
  OPTION_Employee: `/common/options-employee.php`,
  
  
};
 

const OptionService = () => {
  const optionsProductspec = (parm = {}) => api.get(`${API_URL.OPTION_PRODUCTSPEC}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsProductsize = (parm = {}) => api.get(`${API_URL.OPTION_PRODUCTSIZE}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsProduct = (parm = {}) => api.get(`${API_URL.OPTION_PRODUCT}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsProvince = (parm = {}) => api.get(`${API_URL.OPTION_Province}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsDistrict = (parm = {}) => api.get(`${API_URL.OPTION_District}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsSubdistrict = (parm = {}) => api.get(`${API_URL.OPTION_Subdistrict}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsZipcode = (parm = {}) => api.get(`${API_URL.OPTION_Zipcode}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsEmployee = (parm = {}) => api.post(`${API_URL.OPTION_Employee}?${getParmeter(parm)}`, { ignoreLoading : true });
  

  return {
    optionsProductspec,
    optionsProductsize,
    optionsProduct,
    optionsProvince,
    optionsDistrict,
    optionsSubdistrict,
    optionsZipcode,
    optionsEmployee,
  };
};

export default OptionService;