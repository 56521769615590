/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  Row,
  Space,
  message,
  Spin,
  Select,
  InputNumber,
  Badge,
} from "antd";
import { Form } from "antd";
import { ButtonBack } from "../../components/button";
import { useLocation, useNavigate } from "react-router-dom";

import { SaveFilled } from "@ant-design/icons";

import ProductService from "../../service/Product.service.js";
import OptionsService from "../../service/Options.service.js";

import { delay } from "../../utils/util";

const opService = OptionsService();
const ProductServices = ProductService();

function ProductManage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = location.state || { config: null };

  const [form] = Form.useForm();

  const [formDetail, setFormDetail] = useState({});

  const [optionSpec, setOptionSpec] = useState([]);
  const [optionSize, setOptionSize] = useState([]);

  useState(false);

  const [loading, setLoading] = useState(true);

  const handleConfirm = () => {
    form.validateFields().then((v) => {
      const parm = { ...formDetail, ...v };
      const actions =
        config?.action !== "create"
          ? ProductServices.update(parm)
          : ProductServices.create(parm);
      actions
        .then(async (r) => {
          message.success("Request success.");

          handleClose();
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "error request");
        });
    });
  };

  const getlistOption = async () => {
    await opService.optionsProductspec().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSpec(data);
    });

    await opService.optionsProductsize().then((res) => {
      let { data } = res.data;
      // console.log(data)
      setOptionSize(data);
    });
  };

  const handleClose = async () => {
    navigate("/product", { replace: true });
    await delay(300);
    console.clear();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getsupData = (v) => {
    ProductServices.get(v)
      .then(async (res) => {
        const { data } = res.data;

        const init = {
          ...data,
        };

        setFormDetail(init);
        form.setFieldsValue({ ...init });
      })
      .catch((err) => {
        console.log(err);
        message.error("Error getting infomation Product.");
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  };

  useEffect(() => {
    getlistOption();
    if (!config) {
      handleClose();
      return;
    }
    // setLoading(true);
    if (config?.action !== "create") {
      getsupData(config.code);
    } else {
      setLoading(false);
    }

    return () => {
      form.resetFields();
    };
  }, []);

  const SectionBottom = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target="/product" />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            className="bn-center justify-center"
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  return (
    <Spin spinning={loading}>
      <div
        id="packing-set-manage"
        className="px-0 sm:px-0 md:px-8 lg:px-8"
        style={{
          paddingLeft: 100,
          paddingTop: 20,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space
          style={{ width: "50%" }}
          direction="vertical"
          className="flex gap-4"
        >
          {/* {SectionTop} */}
          <Flex className="width-100" vertical gap={4}>
            <Divider orientation="left" className="!my-0">
              {config.title}
            </Divider>
            <Card>
              <Form form={form} layout="vertical" autoComplete="off">
                <Row
                  gutter={[8, 8]}
                  style={{ padding: "0 2.5rem" }}
                  className="px-4 sm:px-0 md:px-0 lg:px-0"
                >
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item
                      label="รหัสสินค้า"
                      name="product_id"
                      required={false}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item
                      label="Spec Name"
                      name="product_spec_id"
                      rules={[{ required: true, message: "กรุณาเลือกสเป็ค" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        filterOption={filterOption}
                        options={optionSpec.map((item) => ({
                          value: item.spec_id,
                          label: item.spec_name + " / " + item.spec_type,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={24}>
                    <Form.Item
                      label="Product Size"
                      name="product_size_id"
                      rules={[{ required: true, message: "กรุณาเลือกขนาด" }]}
                    >
                      <Select
                        size="large"
                        showSearch
                        // defaultValue={"201/100"}
                        filterOption={filterOption}
                        options={optionSize.map((item) => ({
                          value: item.productsizeid,
                          label: item.size_min + "/" + item.size_max,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                  >
                    <Form.Item
                      label="ราคาค่าแกะ /kg"
                      name="cost"
                      rules={[{ required: true, message: "กรุณากรอกราคา" }]}
                    >
                      <InputNumber
                        min={1}
                        style={{ width: "100%" }}
                        size="large"
                        addonAfter="บาท"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    style={
                      config?.action === "edit"
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  >
                  <Form.Item label="สถานการใช้งาน" name="active_status">
                    <Select
                      size="large"
                      options={[
                        {
                          value: "Y",
                          label: (
                            <Badge status="success" text="เปิดการใช้งาน" />
                          ),
                        },
                        {
                          value: "N",
                          label: <Badge status="error" text="ปิดการใช้งาน" />,
                        },
                      ]}
                    />
                  </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Flex>
          <br />
          {SectionBottom}
        </Space>
      </div>
    </Spin>
  );
}

export default ProductManage;
