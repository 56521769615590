import { formatCommaNumber } from "../../../utils/util";

/** get column for view sample preparation detail */
export const spColumnsView = [
  // {
  //   title: "ลำดับ",
  //   key: "index",
  //   align: "left",
  //   width: '5%',
  //   render: (_, record, idx) => <span key={record?.id}>{idx + 1}</span>,
  // },
  // {
  //   title: "id",
  //   key: "id",
  //   dataIndex: "id",
  //   width: '10%',
  // },
    {
      title: "Spec Name",
      key: "spec_name",
      dataIndex: "spec_name",
      width: '10%',
    },
    {
      title: "Spec Type",
      key: "spec_type",
      dataIndex: "spec_type",
      width: '20%',
    },
    {
      title: "วันที่",
      key: "date",
      dataIndex: "date",
      width: '20%',
    },            
    {
      title: "Min",
      key: "size_min",
      dataIndex: "size_min", 
      align: "right",
      width: '10%',
      className:"field-edit pe-2",
      render: (v) => formatCommaNumber(Number( v || 0 )),
    },
    {
      title: "Max",
      key: "size_max",
      dataIndex: "size_max", 
      align: "right",
      width: '10%',
      className:"field-edit pe-2",
      render: (v) => formatCommaNumber(Number( v || 0 )),
    },
    {
      title: "ราคา/กิโล",
      align: "right",
      width: '10%',
      className:"pe-2",
      key: "cost",
      dataIndex: "cost",
      render: (v) => formatCommaNumber(Number( v || 0 ),2),
    },
    {
      title: "จำนวนที่แกะได้",
      align: "right",
      width: '10%',
      className:"pe-2",
      key: "unit_weight",
      dataIndex: "unit_weight",
      render: (v) => formatCommaNumber(Number( v || 0 ),2),
    },
    {
      title: "เปลือกกุ้ง",
      key: "shrimp_shell",
      dataIndex: "shrimp_shell",
      width: '10%',
      align: "right",
    },    
    {
      title: "ยอดเงินที่ได้",
      align: "right",
      width: '10%',
      className:"pe-2",
      key: "amount_total",
      dataIndex: "amount_total",
      render: (v, record, idx) => formatCommaNumber(record.cost * record.unit_weight,2) ,
    }
    // ,
    // {
    //   title: "Method",
    //   align: "left",
    //   key: "method",
    //   dataIndex: "method", 
    //   className:"field-edit ps-2",
    //   width: '25%',
    // } 
] 

/** get column for view sample preparation parameter */

export const parameterColumnView = [
    {
      title: "Parameter Name",
      key: "paraname",
      dataIndex: "paraname", 
      align: "left", 
      width: '35%',
    },
    {
      title: "Preparation Value",
      align: "left",
      width: '20%',
      key: "preparation",
      dataIndex: "preparation",
      className:"field-edit",
    },
    {
      title: "Cut Out Value",
      align: "left",
      width: '20%',
      key: "cutout",
      dataIndex: "cutout",
      className:"field-edit",
    },
    {
      title: "Remark",
      align: "left",
      key: "remark",
      width: '25%',
      dataIndex: "remark",
      className:"field-edit",
    },
];