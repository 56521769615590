import {
  RobotOutlined,
  TeamOutlined,
  UserOutlined,
  GoldOutlined,
  TagOutlined,
} from "@ant-design/icons";
// import { Authenticate } from "./service/Authenticate.service";
// const authService =  Authenticate();
let _nav = [
  {
    title: "REPORTS",
    type: "group",
    role:['ผู้จัดการ','ผู้ช่วยผู้จัดการ','ฝ่ายบุคคล', 'Admin']
  },
  {
    title: "Report Peelshrimp",
    icon: <RobotOutlined className="nav-ico" />,
    to: "/report_peelshrimp",
    role:['ผู้จัดการ','ผู้ช่วยผู้จัดการ','ฝ่ายบุคคล', 'Admin']
  },
  {
    title: "DATA",
    type: "group",
  },
  {
    title: "Peelshrimp System",
    icon: <RobotOutlined className="nav-ico" />,
    to: "/weightdata",
    role:['ผู้จัดการ','ผู้ช่วยผู้จัดการ','ฝ่ายบุคคล', 'Admin']
  },
  {
    title: "MASTER",
    type: "group",
  },
  {
    title: "Employee",
    icon: <TeamOutlined className="nav-ico" />,
    to: "/employee",
    role:['ฝ่ายบุคคล', 'Admin']
  },  
  {
    title: "Product",
    icon: <GoldOutlined className="nav-ico" />,
    to: "/product",
    role:['ผู้จัดการ','ผู้ช่วยผู้จัดการ','Admin']
  },
  {
    title: "Unit",
    icon: <TagOutlined className="nav-ico" />,
    to: "/unit",
    role:['ผู้ช่วยผู้จัดการ','Admin','พนักงาน']
  },
  {
    title: "User",
    icon: <UserOutlined className="nav-ico" />,
    to: "/user",
    role:['ผู้จัดการ','ผู้ช่วยผู้จัดการ','Admin']
  },
// ].filter( (item) => {
//   return ( !item.role || !!item?.role?.includes( authService.getType() ) );
// } );
 ];

export default _nav;
