import { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Button, Typography, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { Authenticate } from "../../service/Authenticate.service";
const authService = Authenticate();

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ name, subName, onPress }) {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => window.scrollTo(0, 0));
  useEffect(() => {
    const users = authService.getUserInfo();
    setUserInfo(users);

    return () => {};
  }, []);
  const getIndexRoute = (r) => {
    const linkRoute = r.split("/");
    let routeString = r
      .split("/")
      .slice(0, 2)
      .map((m, index) => {
        let rInx = linkRoute.filter((f, i) => i <= index);

        return {
          title: m,
          href: `/${rInx.join("/")}${
            index > 0 ? `/${r.split("/").slice(2).join("/")}` : ""
          }`,
        };
      });

    return routeString;
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
        </Col>
        <Col span={24} md={6}>
          <Breadcrumb
            items={[{ title: "Home", href: "/" }, ...getIndexRoute(name)]}
          />
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.split("/").slice(0, 2).join(" : ")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Space className="gap-2">
            <UserOutlined />
            <span
              style={{
                letterSpacing: 0.7,
                fontWeight: 600,
                textTransform: "uppercase",
              }}
            >
              <Typography.Text style={{ color: "#5e5f61" }}>
                {userInfo?.firstname} {userInfo?.lastname}
              </Typography.Text>
            </span>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default Header;
