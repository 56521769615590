import { Badge, Space } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"; 
import { PrinterOutlined } from "@ant-design/icons"; 

export const accessColumn = ({handleView}) => [
  {
    title: "รหัสพนักงาน",
    dataIndex: "empcode",
    key: "empcode",
    //hidden: "true",
    width: "10%",
  },
  {
    title: "คำนำหน้า",
    dataIndex: "prefix",
    key: "prefix",
    width: "10%",
  },
  {
    title: "ชื่อ-สกุล",
    dataIndex: "fullname",
    key: "firstname",
    width: "50%",
  },
  {
    title: "สถานะพนักงาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "10%",
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เป็นพนักงาน" />
        ) : (
          <Badge status="error" text="ไม่ได้เป็นพนักงาน" />
        )}
      </div>
    ),
  },
  {
    title: "Action",
    key: "position",
    width: "20%",
    fixed: "right",
    render: (text, record) => (
      <Space > 
         <Button
            icon={<PrinterOutlined />} 
            className='bn-warning-outline'
            style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={(e) => handleView(record.empcode) }
            size="small"
          />
      </Space>
    ),
  },  
]; 